<!-- eslint-disable vue/valid-template-root -->
<script>
import dayjs from 'dayjs';
import { Icon } from '@iconify/vue2';
import { mapGetters } from 'vuex';

export default {
  name: 'PaymentRow',
  components: {
    Icon,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    payment: {
      type: Object,
      required: true,
    },
    anyActiveRow: Boolean,
    collapsed: Boolean,
    isRedirectToDetail: {
      type: Boolean,
      default: true,
    },
    withVerification: {
      type: Boolean,
      default: false,
    },
    paymentAmountAlignRight: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle'],
  methods: {
    dayjs,
    getRelationships(resource, id) {
      let data = resource.included?.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    verifyPayment(payment) {
      this.$emit('verify-payment', payment);
    },
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
      getUser: 'auth/getUser',
    }),
    isOnlyBalanceAndNoAmount() {
      return (
        this.payment.attributes.is_only_using_order_balance &&
        this.payment.attributes.total_amount -
          this.getOrder.data.attributes.cashback <
          1
      );
    },
    isPaymentKp() {
      return (
        this.getSingleIncludedType(
          'origin-office',
          this.getOrder,
          this.getOrder.data
        )?.attributes?.office_type === 'kantor_pelayanan'
      );
    },
  },
};
</script>

<template>
  <tr class="bg-white">
    <td class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
      <router-link
        v-if="isRedirectToDetail"
        :to="`${order.id}/faktur-pembayaran/${payment.id}`"
        class="inline-flex items-center hover:text-blue-600"
      >
        <Icon icon="ic:outline-remove-red-eye" class="mr-2" />
        {{ payment?.attributes?.code }}
      </router-link>
      <span v-else class="inline-flex items-center">{{
        payment?.attributes?.code
      }}</span>
    </td>
    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
      {{
        getOrder.data.relationships['destination-office'].data
          ? getRelationships(
              getOrder,
              getOrder.data.relationships['destination-office'].data.id
            )?.attributes.code
          : '-'
      }}
      /
      {{
        getOrder.data.relationships['origin-warehouse'].data
          ? getRelationships(
              getOrder,
              getOrder.data.relationships['origin-warehouse'].data.id
            )?.attributes.code
          : '-'
      }}
    </td>
    <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
      {{ dayjs(payment?.attributes?.createdAt).format('ll LT') }}
    </td>
    <td
      class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
      :class="{ 'text-right': paymentAmountAlignRight }"
    >
      {{ payment?.attributes?.total_amount | toCurrency }}
    </td>
    <td
      v-if="withVerification"
      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
    >
      <template v-if="isOnlyBalanceAndNoAmount">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="mx-auto h-6 text-green-500"
          title="Pembayaran telah diverifikasi"
        >
          <path
            fill-rule="evenodd"
            d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </template>
      <template v-else-if="isPaymentKp && this.getUser.office_type === 'pusat'">
        <svg
          title="Pembayaran diverifikasi oleh KP"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="mx-auto h-6 w-6 text-yellow-500"
        >
          <path
            fill-rule="evenodd"
            d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
            clip-rule="evenodd"
          />
        </svg>
      </template>
      <template v-else-if="payment.attributes.state == 'pending'">
        <svg
          title="Metode pembayaran harus diverifikasi terlebih dahulu"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="mx-auto h-6 w-6 text-yellow-500"
        >
          <path
            fill-rule="evenodd"
            d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
            clip-rule="evenodd"
          />
        </svg>
      </template>
      <template v-else-if="payment.attributes.is_verified">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="mx-auto h-6 text-green-500"
          title="Pembayaran telah diverifikasi"
        >
          <path
            fill-rule="evenodd"
            d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </template>
      <button
        v-else
        title="Klik untuk verifikasi"
        @click="verifyPayment(payment)"
        class="mx-auto block rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg disabled:cursor-pointer disabled:bg-gray-300 disabled:hover:shadow-none"
      >
        Verifikasi
      </button>
    </td>
    <td
      @click="$emit('toggle', payment.id)"
      class="flex cursor-pointer justify-center whitespace-nowrap px-6 py-4 text-sm text-gray-500"
    >
      <button name="toggle" role="switch">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mx-auto h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
          v-if="!collapsed"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M5 15l7-7 7 7"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
          v-else
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
    </td>
  </tr>
</template>
